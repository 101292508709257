import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, TextField, Switch, BottomNavigation, BottomNavigationAction, useMediaQuery } from '@mui/material';
import { Home, Notifications, Settings, Person, Lock } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Test from './Test';
import Learn from './Learn';
import UserForm from './UserForm';
import Assignment from './Assignment';
import Status from './Status';

const Dashboard = ({ isLoggedIn, username, userRole, handleLogout }) => {
  const [selectedTab, setSelectedTab] = useState('Your Status');
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/LoginPage');
    }
  }, [isLoggedIn, navigate]);



  const renderContent = () => {
    switch (selectedTab) {
      case 'Your Status':
        return <Status />;
      case 'Task':
        return <Test />;
      case 'Courses':
        return <Learn/>
      case 'Assignments':
        return <Assignment />;
      case 'Company':
        return (
          <Typography variant="h6" color="error" sx={{ textAlign: 'center', mt: 4 }}>
            This section is locked.
          </Typography>
        );
      case 'Profile':
        return (
          <>
            <h1>Profile</h1>
            <UserForm />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar
        isLoggedIn={isLoggedIn}
        username={username}
        userRole={userRole}
        handleLogout={handleLogout}
      />

      <div>
        {/* Sidebar */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            left: '15px',
            borderRadius:'4px',
            marginTop: '20px',
            width: '250px',
            height: '82%',
            backgroundColor: '#1976d2',
            color: '#fff',
            transition: 'transform 0.3s ease',
            transform: isMobile ? 'translateX(-100%)' : 'translateX(0)',
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ padding: '20px', marginTop: '10px' }}>
            Dashboard
          </Typography>
          <Divider sx={{ marginBottom: '20px', backgroundColor: '#fff' }} />
          <Box sx={{ padding: '20px', flexGrow: 1 }}>
            {[
              { label: 'Your Status' },
              { label: 'Task' },
              { label: 'Courses' },
              { label: 'Assignments', locked: true },
              { label: 'Company', locked: true },
              { label: 'Profile' },
            ].map((tab) => (
              <Typography
                key={tab.label}
                onClick={() => {
                  if (!tab.locked) setSelectedTab(tab.label);
                }}
                sx={{
                  cursor: tab.locked ? 'not-allowed' : 'pointer',
                  marginBottom: '16px',
                  color: tab.locked ? '#888' : '#fff',
                  color: selectedTab === tab.label ? '#001920' : '#fff',
                  backgroundColor: selectedTab === tab.label ? 'yellow' : 'blue',
                  fontWeight: selectedTab === tab.label ? 'bold' : 'normal',
                  padding: '10px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  transition: 'all 0.3s ease',
                }}
              >
                {tab.locked && <Lock sx={{ fontSize: '16px' }} />}
                {tab.label.charAt(0).toUpperCase() + tab.label.slice(1)}
              </Typography>
            ))}
          </Box>


        </Box>

        {/* Main Content */}
        <Box sx={{ marginLeft: isMobile ? 0 : '250px', padding: '20px' }}>
          {renderContent()}
        </Box>

        {/* Bottom Navigation for Mobile */}
        {isMobile && (
          
          <BottomNavigation
            value={selectedTab}
            onChange={(event, newValue) => {
              if (newValue !== 'Company') {
                setSelectedTab(newValue);
              }
            }}
            showLabels
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: '#000e79',
              zIndex: 1300,
              '& .Mui-selected': {
                color: '#fff',
              },
              '& .MuiBottomNavigationAction-root': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
            }}
          >
            <BottomNavigationAction label="Your Status" value="Your Status" icon={<Home />} />
            <BottomNavigationAction label="Task" value="Task" icon={<Notifications />} />
            <BottomNavigationAction label="Courses" value="Courses" icon={<Lock />} />
            <BottomNavigationAction label="Assignments" value="Assignments" icon={<Notifications />} />
            <BottomNavigationAction label="Company" value="Company" icon={<Lock />} />
            <BottomNavigationAction label="Profile" value="Profile" icon={<Person />} />
          </BottomNavigation>
        )}
      </div>
    </>
  );
};

export default Dashboard;
