import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './index.css'
import Navbar from './Navbar.js'
import Banner from './Banner.js'
import LogoSection from './LogoSection.js'
import Enquiry from './Enquiry.js'
import Footer from './Footer.js'
import SignUp from './SignUp.js'
import LoginPage from './LoginPage.js'
import Test from './Test.js'
import McqTestPage from './McqTestPage.js'
import QuestionPage from './QuestionPage'
import ProgrammingTestPage from './ProgrammingTestPage.js'
import CourseJava from './CourseJava.js'
import CoursePython from './CoursePython.js'
import CourseC from './CourseC.js'
import Dashboard from './Dashboard.js'
import Courses from './courses.js'
import Userdashboard from './CollegeAdminDashboard.js'
import Admindashboardg from './CollegeStudentDashboard.js'
import UploadQuestions from './Uploadquestions.js'
import TestPage from './Testpage.js'
import Cwithus from './chatwithus'
import Company from './Company.js'
import Enquire from './Enquire.js'
import OurProducts from './OurProducts.jsx'
import TrainerDashboard from './TrainingComponent/TrainerDashboard.js'
import CreativeForm from './TrainingComponent/CreativeForm'
import UserForm from './UserForm.js'
import Assignments from './Assignments.js'
import apiClient from './utils/apiClient'
import Status from './Status.js'
import MCQQuiz from './MCQQuiz.js'
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [username, setUsername] = useState('')
  const [userRole, setUserRole] = useState('')

  // Check for logged-in user on page load
  // useEffect(() => {
  //   const storedUser = localStorage.getItem("username");
  //   const storedRole = localStorage.getItem("role");

  //   if (storedUser && storedRole) {
  //     setIsLoggedIn(true);
  //     setUsername(storedUser);
  //     setUserRole(storedRole);

  //     // Fetch user role from API using apiClient
  //     const fetchUserRole = async () => {
  //       try {
  //         const data = await apiClient("quiz/users/create/user/");
  //         const user = data.find((user) => user.username === storedUser);
  //         if (user) {
  //           setUserRole(user.role);
  //         }
  //       } catch (error) {
  //         // console.error("Error fetching user data:", error);
  //       }
  //     };

  //     fetchUserRole();
  //   }
  // }, []);

  // Handle automatic logout on root path
  useEffect(() => {
    if (location.pathname === "/") handleLogout();
  }, [location]);

  // Handle logout logic
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername("");
    setUserRole("");
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    sessionStorage.removeItem("userID");
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/LoginPage"
          element={
            <LoginPage
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
              setIsLoggedIn={setIsLoggedIn}
              setUsername={setUsername}
              setUserRole={setUserRole}
            />
          }
        />
        <Route
          path="/SignUp"
          element={
            <SignUp
              setIsLoggedIn={setIsLoggedIn}
              setUsername={setUsername}
              setUserRole={setUserRole}
            />
          }
        />
        <Route
          path="/Test"
          element={
            <Test
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/McqTestPage"
          element={
            <McqTestPage
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/QuestionPage"
          element={
            <QuestionPage
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/ProgrammingTestPage"
          element={
            <ProgrammingTestPage
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/CourseJava"
          element={
            <CourseJava
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/CoursePython"
          element={
            <CoursePython
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/CourseC"
          element={
            <CourseC
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        
        <Route
          path="/Status"
          element={
            <Status
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/Dashboard"
          element={
            <Dashboard
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/courses"
          element={
            <Courses
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />

        <Route
          path="/adminDashboard"
          element={
            <Userdashboard
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route path="/chatwithus" element={<Cwithus />} />
        <Route
          path="/Userdashboard"
          element={
            <Admindashboardg
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/TestPage"
          element={
            <TestPage
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/Uploadquestions"
          element={
            <UploadQuestions
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/Company"
          element={
            <Company
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/assignments"
          element={
            <Assignments
              isLoggedIn={isLoggedIn}
              userRole={userRole}
              handleLogout={handleLogout}
              username={username}
            />
          }
        />
        <Route
          path="/Enquire"
          element={
            <Enquire
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/TrainerDashboard"
          element={
            <TrainerDashboard
              isLoggedIn={isLoggedIn}
              username={username}
              userRole={userRole}
              handleLogout={handleLogout}
            />
          }
        />
        <Route
          path="/trainer-form"
          element={
            <CreativeForm
              isLoggedIn={isLoggedIn}
              userRole={userRole}
              handleLogout={handleLogout}
              username={username}
            />
          }
        />


        <Route
          path="/"
          element={
            <>
              <Navbar
                isLoggedIn={isLoggedIn}
                username={username}
                userRole={userRole}
                handleLogout={handleLogout}
              />
              <Banner isLoggedIn={isLoggedIn}/>
              <LogoSection />
              <OurProducts />
              <Enquiry />
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
