import React, { useState, useEffect } from "react";
import "./MCQQuiz.css";
import { useNavigate } from "react-router-dom";

function MCQQuiz({ 
  questions, 
  updateQuestionStatus, 
  submitTest, 
  userData 
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});
  const [timeLeft, setTimeLeft] = useState(3600);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [attemptedCount, setAttemptedCount] = useState(0);
  const [unattemptedCount, setUnattemptedCount] = useState(questions.length);

  const navigate = useNavigate();

  useEffect(() => {
    const initialAnswers = {};
    questions.forEach((_, index) => {
      initialAnswers[index + 1] = "";
    });
    setSelectedAnswer(initialAnswers);
  }, [questions]);

  useEffect(() => {
    if (timeLeft <= 0) {
      handleSubmitTest();
      return;
    }
    const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  useEffect(() => {
    const attempted = Object.values(selectedAnswer).filter((ans) => ans !== "").length;
    setAttemptedCount(attempted);
    setUnattemptedCount(questions.length - attempted);
  }, [selectedAnswer, questions.length]);

  const parseOptions = (options) => {
    if (!options) return [];
    if (Array.isArray(options)) return options;

    try {
      const parsedOptions = JSON.parse(options.replace(/'/g, '"'));
      if (Array.isArray(parsedOptions)) return parsedOptions;
    } catch {
      return options
        .replace(/\[|\]/g, "")
        .split(",")
        .map((item) => item.trim());
    }

    return [];
  };

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestionIndex + 1]: answer,
    }));
    updateQuestionStatus(questions[currentQuestionIndex].id, "answered");
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleMarkForReview = () => {
    setMarkedForReview((prev) => {
      const updatedState = { ...prev };
      updatedState[currentQuestionIndex] = !updatedState[currentQuestionIndex];
      return updatedState;
    });
  };

  const handleSubmitTest = () => {
    setShowConfirmationDialog(true);
  };

  const handleConfirmation = (isConfirmed) => {
    setShowConfirmationDialog(false);
    if (isConfirmed) {
      if (submitTest && typeof submitTest === "function") {
        submitTest(selectedAnswer);
      }
      localStorage.setItem("submitMessage", "Test Submitted Successfully!");
      navigate(-1);
    }
  };

  const formatTime = (seconds) => {
    const mins = String(Math.floor(seconds / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${mins}:${secs}`;
  };

  return (
    <div className="quiz-layout">
      {showConfirmationDialog && (
        <div className="confirmation-dialog">
          <p>Are you sure you want to submit the test?</p>
          <div className="dialog-buttons">
            <button onClick={() => handleConfirmation(true)}>Yes</button>
            <button onClick={() => handleConfirmation(false)}>No</button>
          </div>
        </div>
      )}

      <div className="question-section">
        <h2>
          Question {currentQuestionIndex + 1} of {questions.length}
        </h2>
        <div className="user-info">
        </div>
        <p>{questions[currentQuestionIndex]?.question}</p>

        <div className="options-grid">
          {parseOptions(questions[currentQuestionIndex]?.options).map(
            (option, i) => (
              <div
                key={i}
                className={`option-item ${
                  selectedAnswer[currentQuestionIndex + 1] === option
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleAnswerSelect(option)}
              >
                {option}
              </div>
            )
          )}
        </div>

        <div className="navigation-buttons">
          <button
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 0 || timeLeft <= 0}
          >
            Previous
          </button>
          <button
            onClick={handleMarkForReview}
            disabled={timeLeft <= 0}
            className={markedForReview[currentQuestionIndex] ? "marked" : ""}
          >
            {markedForReview[currentQuestionIndex]
              ? "Unmark Review"
              : "Mark for Review"}
          </button>
          <button
            onClick={handleNextQuestion}
            disabled={
              currentQuestionIndex === questions.length - 1 || timeLeft <= 0
            }
          >
            Next
          </button>
        </div>
      </div>

      <div className="side-panel">
        <div className="timer-container">
          <h3>Time Left</h3>
          <p className={`timer-text ${timeLeft <= 120 ? "red" : ""}`}>
            {formatTime(timeLeft)}
          </p>
        </div>

        <div className="palette-grid">
          {questions.map((_, index) => (
            <div
              key={index}
              className={`palette-item 
                ${selectedAnswer[index + 1] ? "answered" : ""} 
                ${markedForReview[index + 1] ? "marked yellow" : ""} 
                ${currentQuestionIndex === index ? "current" : ""}`}
              onClick={() => setCurrentQuestionIndex(index)}
            >
              {index + 1}
            </div>
          ))}
        </div>

        <div className="attempt-info">
          <div className="attempt-card attempted">
            Attempted: {attemptedCount}
          </div>

          <div className="attempt-card unattempted">
            Unattempted: {unattemptedCount}
          </div>
        </div>

        <div className="submit-button-container">
          <button
            className="submit-button"
            onClick={handleSubmitTest}
            disabled={timeLeft <= 0}
          >
            Submit Test
          </button>
        </div>
      </div>
    </div>
  );
}

export default MCQQuiz;