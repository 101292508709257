import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import apiClient from './utils/apiClient';
import './Status.css';

const Status = () => {
  const [userEmail, setUserEmail] = useState('');
  const [dailyStats, setDailyStats] = useState([]);
  const [performanceData, setPerformanceData] = useState({
    totalAttempts: 0,
    averageScore: 0,
    completionRate: 0,
    dailyGrowth: 0,
  });
  const [mcqPerformanceData, setMcqPerformanceData] = useState({
    totalAttempts: 0,
    averageScore: 0,
    completionRate: 0,
    dailyGrowth: 0,
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Retrieve user email from localStorage
        const storedEmail = localStorage.getItem('username');
        setUserEmail(storedEmail || 'User ');

        // Fetch daily performance data from API
        const response = await apiClient('dashboard/performance/', 'GET');
        
        if (response && Array.isArray(response)) {
          setDailyStats(response);

          // Calculate performance metrics for User Dashboard
          const totalAttempts = response.reduce((sum, day) => sum + day.attempts, 0);
          const avgScore = response.reduce((sum, day) => sum + day.avgScore, 0) / response.length;
          const avgCompletionRate = response.reduce((sum, day) => sum + day.completionRate, 0) / response.length;
          
          // Calculate daily growth (percentage change from first to last day)
          const dailyGrowth = response.length > 1 
            ? ((response[response.length - 1].avgScore - response[0].avgScore) / response[0].avgScore * 100).toFixed(1)
            : 0;

          setPerformanceData({
            totalAttempts,
            averageScore: avgScore.toFixed(1),
            completionRate: avgCompletionRate.toFixed(1),
            dailyGrowth,
          });
        }

        // Fetch MCQ performance data from API
        const mcqResponse = await apiClient('dashboard/mcq-performance/', 'GET');
        
        if (mcqResponse && Array.isArray(mcqResponse)) {
          // Calculate performance metrics for MCQ Dashboard
          const mcqTotalAttempts = mcqResponse.reduce((sum, day) => sum + day.attempts, 0);
          const mcqAvgScore = mcqResponse.reduce((sum, day) => sum + day.avgScore, 0) / mcqResponse.length;
          const mcqAvgCompletionRate = mcqResponse.reduce((sum, day) => sum + day.completionRate, 0) / mcqResponse.length;

          // Calculate daily growth for MCQ (percentage change from first to last day)
          const mcqDailyGrowth = mcqResponse.length > 1 
            ? ((mcqResponse[mcqResponse.length - 1].avgScore - mcqResponse[0].avgScore) / mcqResponse[0].avgScore * 100).toFixed(1)
            : 0;

          setMcqPerformanceData({
            totalAttempts: mcqTotalAttempts,
            averageScore: mcqAvgScore.toFixed(1),
            completionRate: mcqAvgCompletionRate.toFixed(1),
            dailyGrowth: mcqDailyGrowth,
          });
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <Container fluid>
      {/* Greeting Section */}
      <Row className="mb-4">
        <Col>
          <Card className="text-center p-4">
            <h2>Welcome Back, {userEmail}!</h2>
            <p>Your daily performance stats are below. Keep up the great work!</p>
          </Card>
        </Col>
      </Row>

      {/* User Performance Overview */}
      <Row className="mb-4">
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Header>User Total Attempts</Card.Header>
            <Card.Body>
              <h4 className="text-primary">{performanceData.totalAttempts}</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Header>User Average Score</Card.Header>
            <Card.Body>
              <h4 className="text-success">{performanceData.averageScore}%</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Header>User Completion Rate</Card.Header>
            <Card.Body>
              <h4 className="text-info">{performanceData.completionRate}%</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Header>User Daily Growth</Card.Header>
            <Card.Body>
              <h4 className={`${performanceData.dailyGrowth >= 0 ? 'text-success' : 'text-danger'}`}>
                {performanceData.dailyGrowth}%
              </h4>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* MCQ Performance Overview */}
      <Row className="mb-4">
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Header>MCQ Total Attempts</Card.Header>
            <Card.Body>
              <h4 className="text-primary">{mcqPerformanceData.totalAttempts}</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Header>MCQ Average Score</Card.Header>
            <Card.Body>
              <h4 className="text-success">{mcqPerformanceData.averageScore}%</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Header>MCQ Completion Rate</Card.Header>
            <Card.Body>
              <h4 className="text-info">{mcqPerformanceData.completionRate}%</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Header>MCQ Daily Growth</Card.Header>
            <Card.Body>
              <h4 className={`${mcqPerformanceData.dailyGrowth >= 0 ? 'text-success' : 'text-danger'}`}>
                {mcqPerformanceData.dailyGrowth}%
              </h4>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Charts Section */}
      <Row>
        <Col md={6}>
          <Card className="p-3">
            <Card.Header>Daily Quiz Attempts</Card.Header>
            <Card.Body>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={dailyStats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="attempts" stroke="#6366f1" />
                </LineChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="p-3">
            <Card.Header>Scores & Completion Rates</Card.Header>
            <Card.Body>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={dailyStats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="avgScore" fill="#22c55e" name="Average Score" />
                  <Bar dataKey="completionRate" fill="#6366f1" name="Completion Rate" />
                </BarChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Status;