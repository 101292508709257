import React, { useState,useEffect } from 'react'
import {
  Box,
  Typography,
  Card,
  Divider,
  Button,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material'
import CalendarComponent from './CalendarView' // Import your CalendarComponent
import Navbar from '../Navbar' // Import Navbar
import CreativeForm from './CreativeForm'
import { useMediaQuery } from '@mui/material'
import {
  CalendarMonth,
  PendingActions,
  CheckCircle,
  Cancel,
  Person,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const TrainerDashboard = ({ isLoggedIn, username, userRole, handleLogout }) => {
  const [selectedTab, setSelectedTab] = useState('calendar')

  const [calendarEvents, setCalendarEvents] = useState([
    {
      id: '1',
      title: 'Locked: Training at College A',
      start: new Date('2024-11-20'),
      end: new Date('2024-11-25'),
      color: '#FF6347',
    },
    {
      id: '2',
      title: 'Training at College B',
      start: new Date('2024-12-05'),
      end: new Date('2024-12-10'),
      color: '#42A5F5',
    },
  ])
   const navigate = useNavigate()
  const [requests, setRequests] = useState([
    {
      id: 'req1',
      title: 'Admin: Event at College C',
      status: 'pending',
    },
    {
      id: 'req2',
      title: 'Vendor: Seminar at College D',
      status: 'accepted',
    },
    {
      id: 'req3',
      title: 'Admin: Workshop at College E',
      status: 'denied',
    },
  ])

  const isMobile = useMediaQuery('(max-width: 600px)') // Check if the screen is mobile

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/LoginPage')
    }
  }, [])

  // Handlers
  const handleDateBlock = (newEvent) => {
    setCalendarEvents((prevEvents) => [...prevEvents, newEvent])
  }

  const handleDateUnblock = (eventId) => {
    setCalendarEvents((prevEvents) =>
      prevEvents.filter((event) => event.id !== eventId)
    )
  }

  const handleAcceptRequest = (reqId) => {
    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === reqId ? { ...req, status: 'accepted' } : req
      )
    )
    const acceptedRequest = requests.find((req) => req.id === reqId)
    if (acceptedRequest) {
      setCalendarEvents((prevEvents) => [
        ...prevEvents,
        {
          id: acceptedRequest.id,
          title: acceptedRequest.title,
          start: acceptedRequest.start || new Date(),
          end: acceptedRequest.end || new Date(),
          color: '#42A5F5', // Accepted event color
        },
      ])
    }
  }

  const handleDenyRequest = (reqId) => {
    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === reqId ? { ...req, status: 'denied' } : req
      )
    )
  }

  const renderContent = () => {
    switch (selectedTab) {
      case 'calendar':
        return (
          <>
            <Typography variant="h4" textAlign="center" mb={4}>
              My Calendar
            </Typography>
            <Box mt={4}>
              <CalendarComponent
                events={calendarEvents}
                isEditable={true}
                onDateBlock={handleDateBlock}
                onDateUnblock={handleDateUnblock}
              />
            </Box>
          </>
        )
      case 'pending':
        return (
          <>
            <Typography variant="h4" textAlign="center" mb={4}>
              Pending Requests
            </Typography>
            {requests
              .filter((req) => req.status === 'pending')
              .map((req) => (
                <Card
                  key={req.id}
                  style={{
                    padding: '20px',
                    marginBottom: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '12px',
                  }}
                >
                  <Typography variant="h6">{req.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: Pending
                  </Typography>
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAcceptRequest(req.id)}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDenyRequest(req.id)}
                    >
                      Deny
                    </Button>
                  </Box>
                </Card>
              ))}
          </>
        )
      case 'accepted':
        return (
          <>
            <Typography variant="h4" textAlign="center" mb={4}>
              Accepted Requests
            </Typography>
            {requests
              .filter((req) => req.status === 'accepted')
              .map((req) => (
                <Card
                  key={req.id}
                  style={{
                    padding: '20px',
                    marginBottom: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '12px',
                  }}
                >
                  <Typography variant="h6">{req.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: Accepted
                  </Typography>
                </Card>
              ))}
          </>
        )
      case 'denied':
        return (
          <>
            <Typography variant="h4" textAlign="center" mb={4}>
              Denied Requests
            </Typography>
            {requests
              .filter((req) => req.status === 'denied')
              .map((req) => (
                <Card
                  key={req.id}
                  style={{
                    padding: '20px',
                    marginBottom: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '12px',
                  }}
                >
                  <Typography variant="h6">{req.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: Denied
                  </Typography>
                </Card>
              ))}
          </>
        )
      case 'Profile':
        return (
          <>
            <CreativeForm />
          </>
        )
      default:
        return null
    }
  }

  return (
    <div>
      <Navbar
        isLoggedIn={isLoggedIn}
        username={username}
        userRole={userRole}
        handleLogout={handleLogout}
      />

      <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
        {!isMobile ? (
          <Box
            style={{
              display: 'flex',
              gap: '20px',
              borderRadius: '12px',
            }}
          >
            {/* Sidebar */}
            <Box
              style={{
                // width: '250px',
                marginTop:'50px',
                height:'300px',
                padding: '20px',
                backgroundColor: '#1976d2',
                color: '#fff',
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Trainer Dashboard
              </Typography>
              <Divider
                style={{ marginBottom: '16px', backgroundColor: '#fff' }}
              />
              {['calendar', 'accepted', 'pending', 'denied', 'Profile'].map(
                (tab) => (
                  <Typography
                    key={tab}
                    onClick={() => setSelectedTab(tab)}
                    style={{
                      marginBottom: '16px',
                      cursor: 'pointer',
                      color:
                        selectedTab === tab
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.7)',
                      fontWeight: selectedTab === tab ? 'bold' : 'normal',
                      transition: 'all 0.3s ease',
                    }}
                  >
                    {tab=='pending' ? 'Pending Requests' : tab=='accepted' ? 'Accepted Trainings' : tab=='denied' ? 'Denied / Expired' : tab=='calendar' ? 'Calendar' :tab}
                  </Typography>
                )
              )}
            </Box>

            {/* Main Content */}
            <Box style={{ flex: 1 }}>{renderContent()}</Box>
          </Box>
        ) : (
          <>
            <Box style={{ flex: 1, paddingBottom: '56px' }}>
              {renderContent()}
            </Box>
            <BottomNavigation
              value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              showLabels
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: '#1976d2', // Solid color, no transparency
                zIndex: 1300, // Ensure it sits above other elements
                '& .Mui-selected': {
                  color: '#fff', // Change the selected tab icon and label color to white
                  transition: 'color 0.3s ease-in-out', // Smooth animation
                },
                '& .MuiBottomNavigationAction-root': {
                  color: 'rgba(255, 255, 255, 0.7)', // Default color for unselected items
                  transition: 'color 0.3s ease-in-out', // Smooth animation
                },
                '& .MuiBottomNavigationAction-root:hover': {
                  color: '#ffffff', // Change color on hover
                },
              }}
            >
              <BottomNavigationAction
                label="Calendar"
                value="calendar"
                icon={<CalendarMonth />}
              />
               <BottomNavigationAction
                label="Accepted Trainings"
                value="accepted"
                icon={<CheckCircle />}
              />
              <BottomNavigationAction
                label="Pending Requests"
                value="pending"
                icon={<PendingActions />}
              />
              <BottomNavigationAction
                label="Denied / Expired"
                value="denied"
                icon={<Cancel />}
              />
              <BottomNavigationAction
                label="Profile"
                value="Profile"
                icon={<Person />}
              />
            </BottomNavigation>
          </>
        )}
      </div>
    </div>
  )
}

export default TrainerDashboard
