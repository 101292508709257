import { useRef, useState, useCallback, useEffect } from 'react';
import { Button, Form, Container, Row, Col, Dropdown, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import MonacoEditor from "@monaco-editor/react";
// import { useLocation } from 'react-router-dom';
import './QuestionPage.css';
import  Navbar  from './Navbar';
import apiClient from './utils/apiClient'
import Draggable from 'react-draggable';
import CryptoJS from 'crypto-js';

const QuestionPage = ({ isLoggedIn ,userRole, handleLogout, username }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [sourceCode, setSourceCode] = useState(`import java.io.*;
import java.util.*;

public class Main {

    public static void main(String[] args) {
        /* Enter your code here. */
    }
}`)

  const [stdin, setStdin] = useState('')
  const [expectedOutput, setExpectedOutput] = useState('')
  const [result, setResult] = useState('')
  const locationData = useLocation();
  const { question } = location.state || {}; // Extract question from state
  const { questionId } = location.state || {}; // Extract question from state
  const [selectedQuestion, setSelectedQuestion] = useState(null); // Tracks the selected question

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [error, setError] = useState(null)
  const [languages, setLanguages] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState('Java')
  const [passedCases, setPassedCases] = useState(0)
  const [totalCases, setTotalCases] = useState(0)
  const [isEditorLocked, setIsEditorLocked] = useState(false)
  const [isTestSubmitted, setIsTestSubmitted] = useState(false);
  const [output, setOutput] = useState('')
  const [showInputOutput, setShowInputOutput] = useState(false)
  const [showTestCases, setShowTestCases] = useState(false)
  const [isRunClicked, setIsRunClicked] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [preventSwitch, setPreventSwitch] = useState(false); // Prevent default switching

  const storedEncryptedUserID = sessionStorage.getItem('userID');
  const bytes = CryptoJS.AES.decrypt(storedEncryptedUserID, 'thirancoding360mgai');
  const userId = bytes.toString(CryptoJS.enc.Utf8);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRun, setIsLoadingRun] = useState(false);
  const editorRef = useRef(null)
  
  const [isAlertVisible, setIsAlertVisible] = useState(false)
  const isAlertVisibleRef = useRef(false); // For non-reactive state tracking
  const [loading, setLoading] = useState(false); // State for loading
  const [consoleHeight, setConsoleHeight] = useState(60);
  const [isTestCaseRunning, setIsTestCaseRunning] = useState(false);  // State for tracking test case running status
  const [input, setInput] = useState('');
  const [consoleOutput, setConsoleOutput] = useState('');
  const [dragStartY, setDragStartY] = useState(0);
  const [startTop, setStartTop] = useState(0);
  const [isConsoleVisible, setIsConsoleVisible] = useState(false);
  const [testCaseResults, setTestCaseResults] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false); // State for confirmation dialog
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State for success message
  const [message, setMessage] = useState('');
  const [showPlaceholder, setShowPlaceholder] = useState(true);


  useEffect(()=>{
     if (!isLoggedIn) {
       navigate('/LoginPage')
     }
  },[])


  useEffect(() => {
    const addFocusAndBlurListeners = (editor) => {
      const placeholderText = "Enter your input here";

      // Set the initial value of the editor to the placeholder if the input is empty
      // if (!input || input === placeholderText) {
      //   editor.setValue(placeholderText);
      // }

      // Get the editor's DOM node
      // const editorNode = editor.getDomNode();

      // Add focus listener to clear placeholder
      const handleFocus = () => {
        if (editor.getValue() === placeholderText) {
          editor.setValue("");
        }
      };

      // Add blur listener to restore placeholder if empty
      const handleBlur = () => {
        if (editor.getValue().trim() === "") {
          editor.setValue(placeholderText);
        }
      };

      // Attach event listeners to the editor DOM node
      // if (editorNode) {
      //   editorNode.addEventListener("focus", handleFocus, true); // Use capture phase
      //   editorNode.addEventListener("blur", handleBlur, true); // Use capture phase
      // }

      // Cleanup listeners when the component unmounts
      return () => {
        // if (editorNode) {
        //   editorNode.removeEventListener("focus", handleFocus, true);
        //   editorNode.removeEventListener("blur", handleBlur, true);
        // }
      };
    };

    return addFocusAndBlurListeners;
  }, [input]);

  
  const handleTabSwitch = useCallback(() => {
    console.log("Tab switched or focus lost");
  
    // Guard: Only proceed if no alert is visible and the test is not submitted
    if (isAlertVisibleRef.current || isTestSubmitted) {
      console.log("Alert already visible or test submitted");
      return;
    }
  
    isAlertVisibleRef.current = true; // Update ref to track alert visibility
    setIsAlertVisible(true); // Show the alert
    console.log("Starting alert process");
  
    // Stop the alert after 4 seconds
    const alertTimeout = setTimeout(() => {
      console.log("Clearing alert after timeout");
      isAlertVisibleRef.current = false; // Reset ref
      setIsAlertVisible(false); // Hide alert
      clearTimeout(alertTimeout); // Clear the timeout
    }, 4000);
  }, [isTestSubmitted]); // Dependency ensures proper memoization
  
  // Throttle function to limit multiple calls
  const throttle = (func, delay) => {
    let lastTime = 0;
    return function (...args) {
      const now = new Date().getTime();
      if (now - lastTime >= delay) {
        func(...args);
        lastTime = now;
      }
    };
  };
  
  useEffect(() => {
    const handleVisibilityChange = () => {
      console.log(`Document visibility changed: ${document.hidden}`);
      if (document.hidden) {
        throttledTabSwitch();
      }
    };
  
    const handleFocusLost = () => {
      console.log("Window focus lost");
      throttledTabSwitch();
    };
  
    // Create a throttled version of handleTabSwitch
    const throttledTabSwitch = throttle(handleTabSwitch, 2000); // Throttle calls to once every 2 seconds
  
    // Add event listeners for focus and visibility changes
    window.addEventListener("blur", handleFocusLost);
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    // Cleanup event listeners on unmount
    return () => {
      console.log("Cleaning up listeners");
      window.removeEventListener("blur", handleFocusLost);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleTabSwitch]); // Dependency ensures up-to-date reference

  // Restrict clipboard actions
  useEffect(() => {
    const disableClipboard = (e) => {
      if (!isAlertVisible) {
        setIsAlertVisible(true) // Set alert visibility to true
        e.preventDefault() // Block the clipboard action
        alert('Copy-pasting is disabled in this environment!')
        setTimeout(() => setIsAlertVisible(false), 1000) // Allow alerts after 1 second
      }
    }

    const handlePaste = (e) => {
      if (!isAlertVisible) {
        setIsAlertVisible(true)
        e.preventDefault()
        console.log('Paste event triggered')
        alert('Paste is disabled in this environment!')
        setTimeout(() => setIsAlertVisible(false), 1000)
      }
    }

    const editorNode = editorRef.current

    if (editorNode && editorNode.addEventListener) {
      editorNode.addEventListener('paste', handlePaste)
    }

    // Attach global clipboard listeners
    document.addEventListener('copy', disableClipboard)
    document.addEventListener('cut', disableClipboard)
    document.addEventListener('paste', handlePaste)

    // Cleanup event listeners
    return () => {
      if (editorNode && editorNode.removeEventListener) {
        editorNode.removeEventListener('paste', handlePaste)
      }
      document.removeEventListener('copy', disableClipboard)
      document.removeEventListener('cut', disableClipboard)
      document.removeEventListener('paste', handlePaste)
    }
  }, [isAlertVisible])


  useEffect(() => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen()
    }
  }, [])


const [questionData, setQuestionData] = useState(null);
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const data = await apiClient('compiler/languages/', 'GET')

        if (Array.isArray(data)) {
          setLanguages(data)
        } else {
          setError('Unexpected data format.')
        }
      } catch (err) {
        setError('Error fetching languages.')
      }
    }
    const fetchQuestionData = async () => {
      try {
        const data = await apiClient(`compiler/question/?question_id=${questionId}`, 'GET')
        setQuestionData(data[0]);
      } catch (err) {
        setError('Error fetching languages.')
      }
    }

    fetchLanguages()
    fetchQuestionData()
  }, [])

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang)
    switch (lang) {
      case 'Java':
        setSourceCode(`import java.io.*;
import java.util.*;

public class Main {

    public static void main(String[] args) {
        /* Enter your code here. */
    }
}`)
        break
      case 'python':
        setSourceCode('# Write your Python code here')
        break
      case 'C++':
        setSourceCode(`#include <iostream>

int main() {
    // Write C++ code here
    std::cout << "Hello, World!";
    return 0;
}`)
        break
      default:
        setSourceCode('')
        break
    }
  }
   // Handle changes in the Monaco Editor
   const handleEditorChange = (value) => {
    setStdin(value);
    if (value.trim() === "") {
      setShowPlaceholder(true); // Show placeholder if input is empty
    } else {
      setShowPlaceholder(false); // Hide placeholder when input is present
    }
  };
  const handleInputChange = (value) => {
    setInput(value); // Update state with Monaco Editor content
  };


  const handleRun = async () => {
    setIsRunClicked(true); // Step 1: Set flag to prevent further actions
  
    setIsTestCaseRunning(false); // Hide test case results
    setConsoleHeight(300); // Adjust console height as needed
  
    // Scroll to the console section
    dragConsoleUp();
  
    // Check if the input field is empty
    if (!input || input.trim() === '' || input === 'Enter your input here') {
      // Set error message instead of alert
      setError('Please provide input in the input field!');
      setIsRunClicked(false); // Reset flag after action
      return; // Exit the function if input is not valid
    }
  
    // Check if the editor contains the default or empty code
    const defaultCode = `import java.io.*;
  import java.util.*;
  
  public class Main {
  
      public static void main(String[] args) {
          /* Enter your code here. */
      }
  }`;
    if (!sourceCode.trim() || sourceCode === defaultCode) {
      setOutput(''); // Clear output
      setError('Enter a valid code.'); // Display error message
      setIsRunClicked(false); // Reset flag after action
      return;
    }
  
    try {
      // Call the compile and execute function
      const result = await compileAndRunCode(sourceCode); // 'await' works because the function is async
  
      // Update the output with the result
      setError(''); // Clear any previous errors
      setOutput(result); // Show the output in the console
    } catch (err) {
      // Handle errors during execution
      setOutput(''); // Clear any previous output
      setError(err.message || 'An error occurred while executing the code.');
    }
    setIsRunClicked(false); // Reset flag after run completion
  };


  const handleRunTestCase = async () => {
    console.log("Run Test Case button clicked");
  
    // Reset compilation-related states
    setOutput("");
    setShowInputOutput(false);
    setError(null);
  
    // Show the loading symbol
    setIsLoading(true);
  
    setIsTestCaseRunning(true);
    setShowTestCases(true);
    setConsoleHeight(300);
    dragConsoleUp();
  
    const requestData = {
      question_id: questionId,
      user_id: userId,
      source_code: sourceCode,
      language: selectedLanguage,
    };
  
    try {
      const response = await apiClient(
        "compiler/run-test/",
        "POST",
        JSON.stringify(requestData),
        { "Content-Type": "application/json" }
      );
  
      console.log("Response:", response);
  
      setIsLoading(false); // Hide the loading symbol after receiving the response
  
      if (response && response.passed_cases !== undefined && response.total_cases !== undefined) {
        const passedCount = response.passed_cases;
        const totalCount = response.total_cases;
        const failedCount = totalCount - passedCount;
  
        const passedTestCases = Array(passedCount).fill("✔");
        const failedTestCases = Array(failedCount).fill("❌");
  
        const resultMessage = (
          <div style={{ display: "flex", gap: "10px" }}>
            {passedTestCases.map((tick, index) => (
              <span
                key={`pass-${index}`}
                style={{
                  color: "darkgreen", // Dark green color for ticks
                  fontSize: "20px",
                  fontWeight: "bold", // Make it bold for better visibility
                }}
              >
                {tick}
              </span>
            ))}
            {failedTestCases.map((cross, index) => (
              <span
                key={`fail-${index}`}
                style={{
                  color: "red", // Red color for crosses
                  fontSize: "20px",
                }}
              >
                {cross}
              </span>
            ))}
          </div>
        );
  
        setTestCaseResults(resultMessage);
      } else {
        // console.error("Unexpected response format:", response);
        setError("Unexpected response format.");
      }
    } catch (err) {
      // console.error("Error during test case run:", err);
      setIsLoading(false); // Hide loading on error
      setError("Error running the test case. Please try again.");
    }
  };
  
  
  
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Show confirmation dialog
  };
  
  const handleConfirmation = (isConfirmed) => {
    setShowConfirmation(false);
  
    if (isConfirmed) {
      setIsTestSubmitted(true); // Update state to mark the test as submitted
      localStorage.setItem("message", "Test Submitted Successfully!"); // Save message
      navigate("/programmingTestPage"); // Navigate to ProgrammingTestPage
    }
  };
  

  useEffect(() => {
    // Prevent tab switching
    const handleVisibilityChange = () => {
      if (document.hidden && !isTestSubmitted) {
        document.title = "Test in Progress!"; // Update tab title
        setShowConfirmation(true);
        window.focus(); // Bring focus back to the tab
      }
    };

    // Attach event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Cleanup event listeners on unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isTestSubmitted]);


  const handleStart = (question) => {
    navigate('/QuestionPage', {
      state: {
        questionId: question.id,
        question,
      },
    });
  };
  
 
  const handleCompile = async () => {
    setIsLoadingRun(true);
    console.log("Run button clicked");
  
    // Always reset states to avoid interference
    setIsTestCaseRunning(false); // Clear test case running state
    setTestCaseResults(null); // Clear test case results
    setShowTestCases(false); // Hide test case section
    setShowInputOutput(true); // Show console for input-output
    setError(null); // Clear any error messages
  
    // Adjust console height and drag up
    setConsoleHeight(300); // Adjust console height
    dragConsoleUp(); // Ensure console is visible
  
    if (!sourceCode.trim()) {
      setOutput("Error: Please enter your code in the editor.");
      return;
    }
  
    const requestData = {
      source_code: sourceCode,
      language: selectedLanguage,
      stdin: stdin,
    };
  
    try {
      const response = await apiClient(
        "compiler/compile/",
        "POST",
        JSON.stringify(requestData),
        { "Content-Type": "application/json" }
      );
  
      if (response.error) {
        setOutput(response.error);
      } else {
        setOutput(response.output || "Compiled successfully!");
      }
      setIsLoadingRun(false);
    } catch (err) {
      // console.error("Error during compilation:", err);
      setOutput("Please give a valid input");
      setIsLoadingRun(false);
    }
  };
  

  const toggleInputOutput = () => {
    setShowInputOutput((prev) => !prev)
    setShowTestCases(false)
  }
  
  const handleEditorWillMount = (monaco) => {
    // Add a command to intercept paste
    monaco.editor.defineTheme('vs-dark', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': '#1E1E1E',
      },
    })
  }

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor

    // Intercept paste events
    // editor.onDidPaste((e) => {
    //   alert('Pasting is disabled in this editor!')
    //   // No further action; paste is effectively blocked
    //   editor.getModel().undo() // Undo the last action, effectively canceling the paste
    // })

    // Disable paste via command
    // editor.addCommand(
    //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_V, // Ctrl+V or Command+V
    //   () => {
    //     alert('Pasting is disabled in this editor!')
    //     return null
    //   }
    // )

    // Block mouse right-click paste (context menu paste)
    // editor.onKeyDown((event) => {
    //   if (
    //     (event.ctrlKey || event.metaKey) &&
    //     event.keyCode === monaco.KeyCode.KEY_V
    //   ) {
    //     alert('Pasting is disabled in this editor!')
    //     event.preventDefault()
    //   }
    // })

    // Disable context menu paste
    editor.onDidPaste(() => {
      // alert('Pasting is disabled in this editor!')
    })

     // Block context menu paste (right-click + paste)
    // editor.addAction({
    //   id: "disable-paste",
    //   label: "Paste is disabled",
    //   keybindings: [
    //     monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_V, // Prevent Ctrl+V
    //     monaco.KeyCode.F10, // Context menu paste
    //   ],
    //   contextMenuGroupId: "navigation",
    //   contextMenuOrder: 1,
    //   run: () => {
    //     alert("Pasting is disabled in this editor!");
    //     return null;
    //   },
    // });
  }

  const handleDescriptionClick = () => {
    setIsSidebarVisible((prev) => !prev); // Toggle sidebar visibility on click
  };


  const handleSidebarToggle = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  
  const handleDragStart = (e) => {
    const consoleBox = document.querySelector('.console-container');
    const currentTop = consoleBox.getBoundingClientRect().top;

    setDragStartY(e.clientY);
    setStartTop(currentTop);

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    e.preventDefault();
    
  };
  

  
  const handleDrag = (e) => {
    const deltaY = e.clientY - dragStartY;
    const newTop = startTop + deltaY;
  
    const consoleBox = document.querySelector('.console-container');
    const parentHeight = document.querySelector('.question-page').offsetHeight;
    
    const minHeight = 60; // Minimum height
    const maxHeight = 300; // Maximum height (500px)
    
    // Calculate the new height based on the top position
    const newHeight = parentHeight - newTop;
  
    // Ensure the height stays within the min and max limits
    if (newHeight >= minHeight && newHeight <= maxHeight) {
      consoleBox.style.top = `${newTop}px`;
      consoleBox.style.height = `${newHeight}px`;
    } else if (newHeight < minHeight) {
      consoleBox.style.top = `${parentHeight - minHeight}px`; // If height goes below minimum, set top accordingly
      consoleBox.style.height = `${minHeight}px`;
    } else if (newHeight > maxHeight) {
      consoleBox.style.top = `${parentHeight - maxHeight}px`; // If height exceeds maximum, set top accordingly
      consoleBox.style.height = `${maxHeight}px`;
    }
  };
  
  const handleDragEnd = () => {
    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', handleDragEnd);
  };



  const requestData = {
    testCaseId: 'example-id',  // Replace with actual data for the test case
    code: 'yourCodeHere', // Replace with the code you want to test
    language: 'JavaScript', // Replace with the language, if applicable
  };
  const consoleRef = useRef(null);

  // Function to drag the console container up
  const dragConsoleUp = () => {
    const consoleBox = document.querySelector('.console-container');
    if (consoleBox) {
      const parentHeight = document.querySelector('.question-page').offsetHeight;
      const desiredHeight = 300; // Desired height after dragging up (adjust as needed)
  
      // Set the new top position to "drag up" the console
      consoleBox.style.top = `${parentHeight - desiredHeight}px`;
      consoleBox.style.height = `${desiredHeight}px`;
    }
  };



  // Handle running the test case
  const handleRunTestCase1 = async () => {
    // Trigger drag up behavior when running test case
    dragConsoleUp();

    const requestData = {
      "user_id": userId,
      "source_code": sourceCode, 
      "language": selectedLanguage,
      "question_id": questionId,
    };

    setIsTestCaseRunning(true);

    // Your logic for running the test case (e.g., API call or function execution)
    try {
      const response = await apiClient('compiler/run-test/', 'POST', requestData);

      if (response.passed_cases !== undefined && response.total_cases !== undefined) {
        setPassedCases(response.passed_cases);
        setTotalCases(response.total_cases);
        setShowTestCases(true);
        setShowInputOutput(false);
      } else {
        setError('Unexpected response format.');
      }
    } catch (err) {
      console.error('Error during test case run:', err);
      setError('Error running the test case. Please try again.');
    }
  };



  const handleEditorDidMount1 = (editor, monaco) => {
    // Set placeholder text if the input is empty
    if (!input) {
      editor.setValue("Enter your input here");
    }

    // Action for placeholder
    editor.addAction({
      id: "placeholder-text",
      label: "Add Placeholder",
      keybindings: [],
      run: () => {
        if (!input) {
          editor.setValue("Enter your input here");
        }
      },
    });

    // Focus event: Clear placeholder text
    editor.onDidFocusEditorText(() => {
      if (editor.getValue() === "Enter your input here") {
        editor.setValue(""); // Clear placeholder text
      }
    });

    // Blur event: Reset placeholder text if empty
    editor.onDidBlurEditorText(() => {
      if (editor.getValue().trim() === "") {
        editor.setValue("Enter your input here");
      }
    });
  };



  return (
    <>
    {/* CSS for Blinking Animation */}
 <style>
        {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
      </style>

       {/* Blinking Red Alert */}
       {isAlertVisible && (
        <div
          style={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "red",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            fontSize: "16px",
            animation: "blink 1s linear infinite",
          }}
        >
          Tab switching is not allowed! Please stay on this page.
        </div>
      )}
      {/* <Navbar 
            isLoggedIn={isLoggedIn} 
            username={username} 
            userRole={userRole} 
            handleLogout={handleLogout} 
          /> */}
          
          <div className="coding-challenge-container question-page">
      {/* Sidebar */}
      <div className={`questionBar ${isSidebarVisible ? "visible" : "hidden"}`}>
        {isSidebarVisible ? (
          <div className="description">
            <div className="question-header">
              <h3>Question Details</h3>
              {/* Button to hide the sidebar */}
              <button onClick={handleSidebarToggle} className="toggle-btn">
                {"<"} {/* Arrow pointing left */}
              </button>
            </div>
            {/* Dynamically rendering question and description */}
            <p>
              <strong>Question:</strong> {questionData?.question || "No question available"}
            </p>
            <p>
              <strong>Description:</strong> {questionData?.description || "No description available"}
            </p>
          </div>
        ) : (
          <button onClick={handleSidebarToggle} className="toggle-arrow">
            &gt; {/* Arrow pointing right */}
          </button>
        )}
      </div>
          <Col
            // md={showInputOutput || showTestCases ? 6 : 9}
            className="code-column d-flex flex-column"
          >
            <div className="code-header d-flex justify-content-between align-items-center">
              <Dropdown>
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  name="lan"
                >
                  {selectedLanguage || 'Select Language'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {languages.length > 0 ? (
                      languages.map((langObj) => (
                        <Dropdown.Item
                          key={langObj.id}
                          onClick={() => handleLanguageChange(langObj.language)}
                        >
                          {langObj.language}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>Loading languages...</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {/* Button Container */}
        <div className="button-container">
          <button className="run-btn-coding" style={{marginRight:"10px"}} onClick={handleCompile}>Run</button>
          {/* <button className="compile-btn" onClick={handleRunTestCase}>Run Testcase</button> */}
          <button className="compile-btn" onClick={handleRunTestCase}>
  Run Testcase
</button>

{isLoadingRun && (
  <div className="loading-overlay">
    <div className="loading-spinner"></div>
    <p>Compiling...</p>
  </div>
)}


      {/* Confirmation Dialog */}
      {showConfirmation && (
        <div className="confirmation-dialog" style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', border: '1px solid #ccc' }}>
          <p>Are you sure you want to submit the test?</p>
          <button className="yes-btn" onClick={() => handleConfirmation(true)}>Yes</button>
          <button className="no-btn" onClick={() => handleConfirmation(false)}>No</button>
        </div>
      )}
    </div>
 
      
        <button className="submit-btn" onClick={handleSubmit}>Submit</button>
      </div>

            <MonacoEditor
              height="700px"
              language={
                selectedLanguage ? selectedLanguage.toLowerCase() : 'plaintext'
              }
              value={sourceCode}
              onChange={(value) => setSourceCode(value)}
              beforeMount={handleEditorWillMount}
              onMount={handleEditorDidMount}
              options={{
                selectOnLineNumbers: true,
                theme: 'vs-dark',
                automaticLayout: true,
                minimap: { enabled: false },
                readOnly: isEditorLocked,
                scrollbar: {
                  verticalScrollbarSize: 0,
                  horizontalScrollbarSize: 0,
                },
                padding: { top: 0, bottom: 0 },
              }}
            />
          </Col>

          <div
        className="console-container"
        style={{ height: `${consoleHeight}px` }}
        onMouseDown={handleDragStart}
      >
     <div className="console-box">
  <div className="console-content">
    {!isTestCaseRunning && (
      <div
        style={{
          display: "flex", // Arrange children in a row
          gap: "20px", // Space between input and output
        }}
      >


  {/* Input Box */}
<div className="io-box" style={{ flex: 1 }}>
  <h4> Custom Input</h4>
  <div
    style={{
      width: "100%", // Make it responsive
      height: "150px", // Increase the height
      border: "1px solid #ccc",
      borderRadius: "5px",
      overflow: "hidden",
    }}
  >
    <MonacoEditor
      height="100%" // Make it fit the container height
      width="100%" // Make it fit the container width
      language="plaintext"
      value={input || ""} // Placeholder text when input is empty
      onChange={handleEditorChange}
      options={{
        minimap: { enabled: false },
        lineNumbers: "on",
        fontSize: 12,
        wordWrap: "on",
        overviewRulerLanes: 0,
        padding: { top: 5, bottom: 5 },
      }}
      // editorDidMount={(editor) => {
      //   // Placeholder text
      //   const placeholderText = "Enter your input here";

      //   // Set the initial value of the editor to the placeholder if the input is empty
      //   if (!input || input === placeholderText) {
      //     editor.setValue(placeholderText); // Set the placeholder text initially
      //   }

      //   // Remove placeholder text on focus (clear when the editor is clicked or focused)
      //   editor.onDidFocusEditorText(() => {
      //     if (editor.getValue() === placeholderText) {
      //       editor.setValue(""); // Clear placeholder text when focused
      //     }
      //   });

      //   // Restore placeholder text on blur if the editor is empty
      //   editor.onDidBlurEditorText(() => {
      //     if (editor.getValue().trim() === "") {
      //       editor.setValue(placeholderText); // Restore placeholder if empty
      //     }
      //   });
      // }}
      editorDidMount={(editor) => {
        addFocusAndBlurListeners(editor);
      }}
    />
  </div>
</div>




       {/* Output Box */}
<div className="io-box" style={{ flex: 1 }}>
  <h4>Output</h4>
  <div
    style={{
      width: "100%", // Make it responsive
      height: "150px", // Increase the height
      border: "1px solid #ccc",
      borderRadius: "5px",
      overflow: "hidden",
    }}
  >
    <MonacoEditor
      height="100%" // Make it fit the container height
      width="100%" // Make it fit the container width
      language="plaintext"
      value={output || "Output will appear here"} // Display the output or a placeholder text
      options={{
        minimap: { enabled: false },
        lineNumbers: "on",
        fontSize: 12,
        wordWrap: "on",
        overviewRulerLanes: 0,
        padding: { top: 5, bottom: 5 },
        readOnly: true, // Make the editor read-only
      }}
    />
        </div>
      </div>
      </div>
    )}
  </div>
</div>

{isTestCaseRunning && (
  <div className="console-box">
    <h4>Test Case Results</h4>
    <div style={{ overflowY: 'auto', padding: '10px' }}>
      {testCaseResults} {/* Directly render JSX here */}
    </div>
             
    {/* Show the loading spinner after the test case results */}
    {isLoading && (
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        <div className="loading-spinner">
          {/* Add your spinner or loading animation here */}
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )}
            </div>
             
  )}
          {showTestCases && (
            <div className="test-case-results">
             
              <ul
                style={{
                  display: "flex",
                  listStyleType: "none",
                  padding: 0,
                }}
                
              >
                {Array.from({ length: totalCases }, (_, index) => (
                  <li key={index} style={{ margin: "0 10px" }}>
                    {index < passedCases ? (
                      <span
                        style={{
                          display: "inline-block",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: "green",
                          color: "white",
                          textAlign: "center",
                          lineHeight: "30px",
                          fontSize: "18px",
                        }}
                      >
                        ✔️
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "inline-block",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          color: "white",
                          textAlign: "center",
                          lineHeight: "30px",
                          fontSize: "18px",
                        }}
                      >
                        ❌
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      
    </>
  )
};

export default QuestionPage;