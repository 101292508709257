import React, { useEffect, useState } from 'react';
import MCQQuiz from './MCQQuiz';
import apiClient from './utils/apiClient';
import { useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const McqTestPage = () => {
  const { state } = useLocation();
  const { subtype } = state || {};
  const { filterCategory } = state || {};
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [questionStatus, setQuestionStatus] = useState({});
  const [userid, setUserid] = useState('');

  const updateQuestionStatus = (questionId, status) => {
    setQuestionStatus((prevStatus) => ({
      ...prevStatus,
      [questionId]: status,
    }));
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const storedEncryptedUserID = sessionStorage.getItem('userID');
        if (storedEncryptedUserID) {
          const bytes = CryptoJS.AES.decrypt(storedEncryptedUserID, 'thirancoding360mgai');
          setUserid(bytes.toString(CryptoJS.enc.Utf8));
        }

        const data = await apiClient(`compiler/filter-by-subtype/?subtype=${subtype}`, 'GET');
        if (Array.isArray(data)) {
          setQuestions(data);
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching MCQ data:', error);
      }
    };

    fetchQuestions();
  }, [subtype]);

  const submitTest = async (answers) => {
    const payloadMcqEvaluate = {
      answers,
      user_id: userid,
      subtype: subtype,
      type: filterCategory || 'Technical',
    };

    try {
      const response = await apiClient(`compiler/evaluate/`, 'POST', JSON.stringify(payloadMcqEvaluate), {
        'Content-Type': 'application/json',
      });

      if (response.user_id) {
        localStorage.setItem('submitMessage', 'Test Submitted Successfully!');
        navigate(-1);
      }
    } catch (error) {
      console.error('Error submitting MCQ data:', error);
    }
  };

  return <MCQQuiz questions={questions} updateQuestionStatus={updateQuestionStatus} submitTest={submitTest} />;
};

export default McqTestPage;
