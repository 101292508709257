import React, { useEffect, useState } from 'react'
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { FaLock } from 'react-icons/fa' // Import lock icon
import './ProgrammingTestPage.css' // Ensure you include your custom CSS
import Navbar from './Navbar'
import apiClient from './utils/apiClient'

const LevelIndicator = ({ level }) => {
  // Set angle based on level (0 = Low, 1 = Medium, 2 = High)
  const angle = level * 90 - 45 // Adjust the angle for the needle

  return (
    <div className="level-indicator">
      <svg className="gauge" viewBox="0 0 100 50">
        {/* Dial background */}
        <path
          d="M 10 50 A 40 40 0 0 1 90 50"
          fill="none"
          stroke="#e6e6e6"
          strokeWidth="10"
        />
        <path
          d="M 10 50 A 40 40 0 0 1 50 10"
          fill="none"
          stroke="#1e88e5"
          strokeWidth="10"
        />
        <path
          d="M 50 10 A 40 40 0 0 1 90 50"
          fill="none"
          stroke="#1e88e5"
          strokeWidth="10"
        />

        {/* Needle */}
        <line
          x1="50"
          y1="50"
          x2={50 + 40 * Math.cos((angle - 90) * (Math.PI / 180))}
          y2={50 + 40 * Math.sin((angle - 90) * (Math.PI / 180))}
          stroke="#333"
          strokeWidth="3"
        />
      </svg>
    </div>
  )
}

const ProgrammingTestPage = ({
  isLoggedIn,
  userRole,
  handleLogout,
  username,
}) => {
  const [questions, setQuestions] = useState([])
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [filterLevel, setFilterLevel] = useState(0) // Track the selected level
  const [needleLevel, setNeedleLevel] = useState(0) // Track the needle level
  const [submissionMessage, setSubmissionMessage] = useState('') // Add submissionMessage state
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/LoginPage')
    }
    const fetchQuestions = async () => {
      try {
        const data = await apiClient('compiler/questions/', 'GET')

        // Assuming response data is already properly structured
        if (data) {
          setQuestions(data)
              const filtered = data.filter(
                (question) => question.level === "0"
              )
            .sort(() => 0.5 - Math.random()) // Shuffle
            .slice(0, 5);
          setFilteredQuestions(filtered)
        }
      } catch (error) {
        setError(
          'Server maintenance is scheduled until 6 AM. Please try again after that'
        )
      } finally {
        setLoading(false)
      }
    }

    fetchQuestions()
  }, [isLoggedIn, navigate])

  // Handle filtering based on the selected level
  const handleFilter = (level) => {
    setFilterLevel(level)
    setNeedleLevel(level) // Update the needle level based on the button clicked
    const filtered = questions.filter(
      (question) => question.level === level.toString()
    )
    .sort(() => 0.5 - Math.random()) 
    .slice(0, level === 0 ? 5 : 2);
    setFilteredQuestions(filtered)
  }

  // Fetch and set submission message from localStorage
  useEffect(() => {
    const message = localStorage.getItem('message')
    if (message && !submissionMessage) {
      setSubmissionMessage(message) // Set message only once
      setTimeout(() => {
        localStorage.removeItem('message')
        setSubmissionMessage('') // Clear message after 4 seconds
      }, 4000)
    }
  }, [submissionMessage])  // Ensure the effect only runs once

  const handleStart = (question) => {
    navigate('/QuestionPage', { state: { questionId: question.id, question } })
  }

  if (loading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    )
  }

  if (error) {
    return (
      <Container className="text-center">
        <Alert variant="danger">{error}</Alert>
      </Container>
    )
  }

  return (
    <>
      <Navbar
        isLoggedIn={isLoggedIn}
        username={username}
        userRole={userRole}
        handleLogout={handleLogout}
      />
      <Container>
        {submissionMessage && <Alert variant="success">{submissionMessage}</Alert>}

        {/* Speedometer Display with Levels */}
        <div className="d-flex justify-content-around align-items-center mb-4">
          <LevelIndicator level={needleLevel} />{' '}
          {/* Pass the needle level here */}
        </div>

        {/* Filter Buttons */}
        <div className="level-buttons-container">
          <button
            className={`level-button low ${
              filterLevel === 0 ? 'selected' : ''
            }`}
            onClick={() => handleFilter(0)}
          >
            Low
          </button>
          <button
            className={`level-button medium ${
              filterLevel === 1 ? 'selected' : ''
            }`}
            onClick={() => handleFilter(1)}
          >
            Medium
          </button>
          <button
            className={`level-button high ${
              filterLevel === 2 ? 'selected' : ''
            }`}
            onClick={() => handleFilter(2)}
          >
            High
          </button>
        </div>

        <Row>
          {filteredQuestions.length > 0 ? (
            filteredQuestions.map((question, index) => (
              <Col key={question.id} md={12} className="mb-4">
                <Card className="question-card">
                  <Card.Body className="d-flex justify-content-between align-items-center">
                    <span>
                      {question.question}
                    </span>

                    <Button
  variant="primary"
  size="sm"
  onClick={() => handleStart(question)} // Pass the question object including ID
  className="d-flex flex-column align-items-center justify-content-center"
  style={{
    minWidth: '80px',
    height: '30px',
    backgroundColor: '#017a8c', // Button background color
    borderColor: '#017a8c', // Button border color
  }} // Consistent button size
>
  Start
</Button>

                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col md={12} className="text-center">
              <p>No questions available for this level</p>
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default ProgrammingTestPage